var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Button', {
    attrs: {
      "type": "error",
      "data-tests": "logout-button"
    },
    on: {
      "click": _vm.click
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.user.signout')) + " "), _c('Icon', {
    attrs: {
      "type": "md-log-out"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }