<template lang="html">
  <Button
    @click="click"
    type="error"
    data-tests="logout-button"
  >
    {{ $t('global.user.signout') }}
    <Icon type="md-log-out" />
  </Button>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'ButtonLogout',
  props: {
    type: {
      type: String,
      default: '',
    },
    long: {
      type: Boolean
    },
    icon: {
      type: String,
      default: 'ios-log-out'
    }
  },
  methods: {
    ...mapActions({
      userLogout: 'user/logout',
      scopeReset: 'scope/resetScope',
      optionsFlush: 'options/flushOptions'
    }),
    click () {
      this.userLogout()
      this.scopeReset()
      this.optionsFlush()
      this.$router.push({path: '/auth/login'})
    }
  }
}
</script>
