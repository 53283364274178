<template lang="html">
  <div style="width:100%">
    <Form
      :model="changePassword"
      :rules="changePasswordRules"
      ref="changePassword"
      style="max-width:300px"
    >
      <FormItem
        prop="oldpassword"
        label="Current Password"
      >
        <!-- <Input
          type="password"
          v-model="changePassword.oldpassword"
          placeholder="Current password"
        /> -->
        <Input
          :type="showPassword"
          placeholder="Password"
          v-model="changePassword.oldpassword"
        >
          <span
            slot="append"
            @click="() => showPassword = showPassword === 'text' ? 'password' : 'text'"
            style="cursor:pointer"
          >
            <i
              v-if="showPassword === 'password'"
              class="far fa-eye-slash"
            />
            <i
              v-else
              class="far fa-eye"
            />
          </span>
        </Input>
      </FormItem>
      <FormItem
        prop="newpassword"
        label="New Password"
      >
        <!-- <Input
          type="text"
          v-model="changePassword.newpassword"
          placeholder="New password"
        /> -->
        <Input
          :type="showPassword2"
          placeholder="Password"
          v-model="changePassword.newpassword"
        >
          <span
            slot="append"
            @click="() => showPassword2 = showPassword2 === 'text' ? 'password' : 'text'"
            style="cursor:pointer"
          >
            <i
              v-if="showPassword2 === 'password'"
              class="far fa-eye-slash"
            />
            <i
              v-else
              class="far fa-eye"
            />
          </span>
        </Input>
        <Button type="primary"
                size="small"
                @click="()=>{this.changePassword.newpassword = generatePassword()}"
        >
          Generate strong password
        </Button>
      </FormItem>
    </Form>
    <div
      class="reset-actions"
    >
      <Button
        type="text"
        @click="cancel"
      >
        {{ $t('global.cancel') }}
      </Button>
      <Button
        type="primary"
        class="btn btn--elevated"
        @click.prevent="resetPassword"
      >
        <span>{{ $t('global.save') }}</span>
      </Button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'FormPassword',
  data () {
    const validatePass = (rule, value, callback) => {
      // password must contain 1 number (0-9)
      // password must contain 1 uppercase letters
      // password must contain 1 lowercase letters
      // password must contain 1 non-alpha numeric number
      // password is 8-16 characters with no space
      const regex = new RegExp(/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,16}$/)
      if (!value.match(regex)) {
        callback(new Error(`password must contain 1 number (0-9), 1 uppercase letter, 1 lowercase letter, 1 non-alpha numeric number and be 8-16 chars`))
      } else {
        callback()
      }
    }
    return {
      showPassword: 'password',
      showPassword2: 'text',
      disableSubmit: false,
      changePassword: {
        oldpassword: '',
        newpassword: this.generatePassword()
      },
      changePasswordRules: {
        oldpassword: [
          {required: true, message: this.$t('global.user.current_pass_required'), trigger: 'blur'}
        ],
        newpassword: [
          {required: true, trigger: 'change', validator: validatePass}
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    })
  },
  methods: {
    generatePassword () {
      const length = 3,
        charset = `abcdefghijklmnopqrstuvwxyz`,
        charsetM = `ABCDEFGHIJKLMNOPQRSTUVWXYZ`,
        chartsetL = "0123456789",
        chartsetU = `"'(§!)-$^=:;,@#`
      let retVal = ""
      for (let i = 0;i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * charset.length))
        retVal += chartsetL.charAt(Math.floor(Math.random() * chartsetL.length))
        retVal += chartsetU.charAt(Math.floor(Math.random() * chartsetU.length))
        retVal += charsetM.charAt(Math.floor(Math.random() * charsetM.length))
      }
      return retVal
    },
    cancel () {
      this.changePassword.oldpassword = ''
      this.changePassword.newpassword = ''
    },
    resetPassword () {
      this.$refs.changePassword.validate((valid) => {
        if (valid) {
          this.$Modal.confirm({
            title: this.$t('global.validation.confirm'),
            okText: this.$t('global.user.update_pass'),
            cancelText: this.$t('global.cancel'),
            onOk: async () => {
              const data = JSON.stringify({
                oldPassword: this.changePassword.oldpassword,
                newPassword: this.changePassword.newpassword
              })
              this.$axios.post('users/updatePassword/', data,
                {
                  headers: {
                    'Accept': 'application/json'
                  }
                })
                .then(response => {
                  if (response.data.success) {
                    this.changePassword.oldpassword = ''
                    this.changePassword.newpassword = ''
                    this.$Message.success(response.data.widget)
                  } else if (response.data.msg) {
                    for (let res of response.data.msg) {
                      this.$Message[res.type](res.msg)
                    }
                  }
                }).catch(error => {
                  this.$Message.error(error.message)
                })
            },
            onCancel: () => {
              return false
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
    .reset-actions {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
    }
</style>
