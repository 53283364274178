<template lang="html">
  <div>
    <Card style="max-width:800px; margin: 0 auto;">
      <p slot="title">
        {{ $t('global.user.account') }}
      </p>
      <div slot="extra">
        <ButtonLogout />
      </div>
      <List>
        <ListItem>
          <span><strong>Name : </strong></span>
          <span class="user-value">{{ user.name }}</span>
        </ListItem>
        <ListItem>
          <span><strong>Email :</strong></span>
          <span class="user-value">{{ user.username }}</span>
        </ListItem>
        <ListItem>
          <span><strong>organisation :</strong></span>
          <span class="user-value">{{ user.organisation }}</span>
        </ListItem>
        <ListItem>
          <span><strong>Jobtitle :</strong></span>
          <span class="user-value">{{ user.jobTitle }}</span>
        </ListItem>
        <ListItem>
          <span><strong>Role :</strong></span>
          <span class="user-value">
            <span v-for="(val, k) of user.roles"
                  :key="k"
            ><Tag :color="val.alias === 'super-admin' ? 'red' : 'blue'">{{ val.alias }}</Tag></span>
          </span>
        </ListItem>
        <ListItem>
          <span><strong>Accessible properties :</strong></span>
          <span class="user-value">{{ scopeProperties.length }}</span>
        </ListItem>
        <ListItem>
          <Row type="flex"
               style="width:100%"
          >
            <div style="width:100%">
              <div class="tile-header tile-header--simple">
                <span class="tile-header-title">
                  <strong>{{ $t('global.user.update_pass') }}</strong>
                </span>
              </div>
              <br>
              <div class="tile-block">
                <FormPassword />
              </div>
            </div>
          </Row>
        </ListItem>
      </List>
      <!-- <div
        v-if="isLoggedIn"
        data-jest="username"
      >
        {{ $t('global.user.logged_as') }} <strong>{{ user.name }} ({{ user.username }})</strong>
      </div> -->

      <!-- <List :style="{marginTop: '10px'}">
        <strong>{{ $t('global.user.properties') }} ({{ scopeProperties.length }})</strong>
        <Scroll>
          <ListItem
            v-for="property in scopeProperties"
            :key="property.id"
          >
            <ListItemMeta
              :title="property.displayName"
              :description="`Id: ${property.id}`"
            />
          </ListItem>
        </Scroll>
      </List> -->
    </Card>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

import FormPassword from '@/views/Login/FormPassword'
import ButtonLogout from '@/views/Login/ButtonLogout'
// import { env } from '@/config'
export default {
  name: 'Account',
  components: {
    ButtonLogout,
    FormPassword
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      scopeProperties: 'scope/properties'
    }),
    isLoggedIn () {
      return !!this.user
    }
  },
  mounted () {
    // if (env === 'prod') this.$ga.page('/account')
  }
}
</script>
<style scoped lang="scss">
.user-value {
  margin-left: 15px;
}
</style>