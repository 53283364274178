var render = function render(){
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('Form', {
    ref: "changePassword",
    staticStyle: {
      "max-width": "300px"
    },
    attrs: {
      "model": _vm.changePassword,
      "rules": _vm.changePasswordRules
    }
  }, [_c('FormItem', {
    attrs: {
      "prop": "oldpassword",
      "label": "Current Password"
    }
  }, [_c('Input', {
    attrs: {
      "type": _vm.showPassword,
      "placeholder": "Password"
    },
    model: {
      value: _vm.changePassword.oldpassword,
      callback: function callback($$v) {
        _vm.$set(_vm.changePassword, "oldpassword", $$v);
      },
      expression: "changePassword.oldpassword"
    }
  }, [_c('span', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "slot": "append"
    },
    on: {
      "click": function click() {
        return _vm.showPassword = _vm.showPassword === 'text' ? 'password' : 'text';
      }
    },
    slot: "append"
  }, [_vm.showPassword === 'password' ? _c('i', {
    staticClass: "far fa-eye-slash"
  }) : _c('i', {
    staticClass: "far fa-eye"
  })])])], 1), _c('FormItem', {
    attrs: {
      "prop": "newpassword",
      "label": "New Password"
    }
  }, [_c('Input', {
    attrs: {
      "type": _vm.showPassword2,
      "placeholder": "Password"
    },
    model: {
      value: _vm.changePassword.newpassword,
      callback: function callback($$v) {
        _vm.$set(_vm.changePassword, "newpassword", $$v);
      },
      expression: "changePassword.newpassword"
    }
  }, [_c('span', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "slot": "append"
    },
    on: {
      "click": function click() {
        return _vm.showPassword2 = _vm.showPassword2 === 'text' ? 'password' : 'text';
      }
    },
    slot: "append"
  }, [_vm.showPassword2 === 'password' ? _c('i', {
    staticClass: "far fa-eye-slash"
  }) : _c('i', {
    staticClass: "far fa-eye"
  })])]), _c('Button', {
    attrs: {
      "type": "primary",
      "size": "small"
    },
    on: {
      "click": function click() {
        _this.changePassword.newpassword = _vm.generatePassword();
      }
    }
  }, [_vm._v(" Generate strong password ")])], 1)], 1), _c('div', {
    staticClass: "reset-actions"
  }, [_c('Button', {
    attrs: {
      "type": "text"
    },
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.cancel')) + " ")]), _c('Button', {
    staticClass: "btn btn--elevated",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.resetPassword.apply(null, arguments);
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('global.save')))])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }