var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Card', {
    staticStyle: {
      "max-width": "800px",
      "margin": "0 auto"
    }
  }, [_c('p', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v(" " + _vm._s(_vm.$t('global.user.account')) + " ")]), _c('div', {
    attrs: {
      "slot": "extra"
    },
    slot: "extra"
  }, [_c('ButtonLogout')], 1), _c('List', [_c('ListItem', [_c('span', [_c('strong', [_vm._v("Name : ")])]), _c('span', {
    staticClass: "user-value"
  }, [_vm._v(_vm._s(_vm.user.name))])]), _c('ListItem', [_c('span', [_c('strong', [_vm._v("Email :")])]), _c('span', {
    staticClass: "user-value"
  }, [_vm._v(_vm._s(_vm.user.username))])]), _c('ListItem', [_c('span', [_c('strong', [_vm._v("organisation :")])]), _c('span', {
    staticClass: "user-value"
  }, [_vm._v(_vm._s(_vm.user.organisation))])]), _c('ListItem', [_c('span', [_c('strong', [_vm._v("Jobtitle :")])]), _c('span', {
    staticClass: "user-value"
  }, [_vm._v(_vm._s(_vm.user.jobTitle))])]), _c('ListItem', [_c('span', [_c('strong', [_vm._v("Role :")])]), _c('span', {
    staticClass: "user-value"
  }, _vm._l(_vm.user.roles, function (val, k) {
    return _c('span', {
      key: k
    }, [_c('Tag', {
      attrs: {
        "color": val.alias === 'super-admin' ? 'red' : 'blue'
      }
    }, [_vm._v(_vm._s(val.alias))])], 1);
  }), 0)]), _c('ListItem', [_c('span', [_c('strong', [_vm._v("Accessible properties :")])]), _c('span', {
    staticClass: "user-value"
  }, [_vm._v(_vm._s(_vm.scopeProperties.length))])]), _c('ListItem', [_c('Row', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "type": "flex"
    }
  }, [_c('div', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "tile-header tile-header--simple"
  }, [_c('span', {
    staticClass: "tile-header-title"
  }, [_c('strong', [_vm._v(_vm._s(_vm.$t('global.user.update_pass')))])])]), _c('br'), _c('div', {
    staticClass: "tile-block"
  }, [_c('FormPassword')], 1)])])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }